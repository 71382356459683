import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';

import { Button, ServicesList, CarpetCalculatorModal } from '../components';

import styles from './services.module.scss';

const Carpet = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { allContentfulCarpetPackages, contentfulCarpetPage } = useStaticQuery(graphql`
    {
      allContentfulCarpetPackages(sort: {fields: contentfulid, order: ASC}) {
        nodes {
          name
          description {
            description
          }
          price
        }
      }
      contentfulCarpetPage {
        title
        subtitle
      }
    }
  `);

  return (
    <>
      <main>
        {/* HERO */}
        <div className={`${styles.hero} ${styles.carpet}`}>
          <div className="container">
            <h1 className="heading">{contentfulCarpetPage.title}</h1>
            <h2 className="subheading">{contentfulCarpetPage.subtitle}</h2>
            <Button
              label="Calculate price"
              onClick={() => {
                setIsOpen(true);
                firebase.analytics().logEvent('open_carpet_calculator');
              }}
            />
          </div>
        </div>
        {/* Content */}
        <div className={styles.content}>
          <div className="container">
            <ServicesList data={allContentfulCarpetPackages.nodes} />
          </div>
        </div>
      </main>
      <CarpetCalculatorModal open={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};

export default Carpet;
